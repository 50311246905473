<template>
<section>
    <div class="container">
        <div class="row">
            <div class="col-12">
              <h1>Aviso de Privacidad</h1>
                <p class="text-right">Última actualización: 16 de febrero de 2021</p>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-sm-10 offset-1">
                <p><strong>Gobierno Fácil SA de CV</strong>, mejor conocido como <a href="https://gobiernofacil.com/">Gobierno Fácil</a>, titular de los derechos de comercialización y uso de todas las aplicaciones o páginas electrónicas que conforman la Plataforma Digital denominada <strong>INE Fácil</strong>, es el responsable del uso y protección de sus datos personales, y al respecto le informamos lo siguiente:</p>
                <div class="separator pt-4"></div>
                <h4>¿Para qué fines utilizaremos sus datos personales?</h4>
                <p>La información que usted nos provea a través del acceso, registro y creación de perfil de usuario en la Plataforma, y/o correo electrónico, y/o llenado de formularios o encuestas electrónicas, en tiempo real o histórico, se procesará y ordenará, para que genere indicadores de datos, mismos que podrémos usar para tomar decisiones pertinentes al negocio, en algunos casos necesarias para el servicio solicitado, que nos permiten y facilitan brindarle una mejor atención:</p>
                <ul>
                    <li>Fines estadísticos</li>
                    <li>Mejoras a la plataforma</li>
                    <li>Mantenimiento y administración de la plataforma</li>
                    <li>Comunicación con usuarios</li>
                    <li>Mercadotecnia o publicitaria</li>
                    <li> Prospección comercial</li>
                </ul>
                <div class="separator pt-4"></div>
                <h4>Uso de <em>Cookies</em></h4>
                <p>Le informamos que, mediante el uso de cookies y tecnologías similares, buscamos garantizar la mejor experiencia posible en la Plataforma, al proporcionarle información personalizada, recordando sus preferencias de servicios y de mercadeo y ayudándolo a obtener la información adecuada. Los datos personales que recabamos a través de estas tecnologías.</p>
                <p>Los datos personales que obtenemos de estas tecnologías de rastreo son los siguientes:</p>
                <ul>
                    <li>Identificadores, nombre de usuario y contraseñas de una sesión</li>
                    <li>Idioma preferido por el usuario</li>
                    <li>Región en la que se encuentra el usuario</li>
                    <li>Tipo de navegador del usuario</li>
                    <li>Fecha y hora del inicio y final de una sesión de un usuario</li>
                </ul>
                <div class="separator pt-4"></div>
                <h4>¿Con quién compartimos su información personal y para qué fines?</h4>
                <p>Los Datos Personales a que se refiere este Aviso podrán ser transferidos a: (i) terceros relacionados y/o aliados comerciales, con la finalidad de engrandecer la propuesta de valor de la plataforma, así como ofrecerle, con base en sus necesidades, otros productos y servicios; 
                (ii) autoridades judiciales, mexicanas y extranjeras, con la finalidad de dar cumplimiento a la Ley, legislación, notificaciones, requerimientos u oficios de carácter judicial; 
                (iii) a proveedores de servicios de internet o infraestructura tecnológica sobre la cual esté montada la Plataforma. En caso de realizar alguna transferencia de sus Datos Personales, en los que se requiera su consentimiento expreso, se lo informaremos a efecto de recabar el mismo.</p>
                <p>En todos los casos, se comunicará el presente Aviso de Privacidad a estos terceros y se asegurará a través de la firma de convenios y/o la adopción de otros documentos vinculantes, que dichos terceros mantengan las medidas de seguridad administrativas, técnicas y físicas necesarias para resguardar sus Datos Personales, así como que dichos terceros únicamente utilicen sus Datos Personales para las finalidades para los cuales fueron recabados.</p>
                <div class="separator pt-4"></div>
                <h4>¿Cómo puede acceder, rectificar o cancelar sus datos personales, u oponerse a su uso?</h4>
                <p>Usted, como titular de los Datos Personales proporcionados, podrá solicitar en cualquier momento, el ejercicio de sus derechos de acceso, rectificación, cancelación u oposición (los “ Derechos ARCO ”) al tratamiento de sus Datos Personales, consistentes en: (i) acceder a sus Datos Personales y a los detalles del tratamiento de los mismos; (ii) rectificar sus Datos Personales en caso de ser inexactos o incompletos; (iii) cancelar sus Datos Personales cuando considere que no se requieren para alguna de las finalidades señalados en este Aviso de Privacidad, estén siendo utilizados para finalidades no consentidas o haya finalizado su relación contractual o de servicio u otra en la plataforma; y (iv) oponerse al tratamiento de sus Datos Personales para fines específicos.</p>
                <p>Para tal fin, usted deberá enviar su petición al correo electrónico soporte@inefacil.com, la cual deberá contener, como mínimo, la siguiente información: 
                (a) su nombre completo, teléfono y correo electrónico, u otro medio idóneo para comunicarle la respuesta a su solicitud; 
                (b) los documentos que acrediten su identidad o, en su caso, la de su representante legal; 
                (c) la descripción clara y precisa de los Datos Personales respecto de los que se busca ejercer alguno de los derechos antes mencionados; y 
                (d) cualquier otro elemento o información que facilite la localización de los Datos Personales, así como cualquier otro documento requerido por la regulación actual en el momento de presentar la solicitud. Usted también podrá solicitar mayor información sobre el procedimiento para ejercer sus Derechos ARCO.</p>
                <p>La respuesta a su solicitud le será dada a conocer dentro de los 20 (veinte) días hábiles siguientes a la fecha de recepción de dicha solicitud, en los términos establecidos en la Ley. No obstante, usted podrá obtener más información acerca del estado que guarda su solicitud y del plazo de respuesta de la misma, 
                contactando al correo electrónico soporte@inefacil.com, donde además podrán atender cualquier aclaración o duda que pudiera tener respecto al tratamiento de sus Datos Personales y el ejercicio de sus Derechos ARCO.</p>
                <div class="separator pt-4"></div>
                <h4>Usted puede revocar su consentimiento para el uso de sus datos personales</h4>
                <p>Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales. Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligación legal requiramos seguir tratando sus datos personales. Asimismo, usted deberá considerar que para ciertos fines, la revocación de su consentimiento implicará que no le podamos seguir prestando el servicio que nos solicitó, o la conclusión de su relación con nosotros.
                Para revocar su consentimiento deberá presentar su solicitud a través del siguiente correo electrónico: soporte@inefacil.com</p>
                <div class="separator pt-4"></div>
                <h4>¿Cómo puede conocer los cambios en este aviso de privacidad?</h4>
                <p>El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras prácticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas.</p>
                <p>Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, a través de la plataforma.</p>
                <div class="separator pt-4"></div>
                <p>Última actualización: 16 de febrero de 2021</p>
            </div>
        </div>
    </div>
</section>
</template>